.list-group {
  max-height: 400px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .list-group-item {
    cursor: pointer;
    &:hover {
      background: #dee2e6;
      &.active {
        background: #007bff;
      }
    }
  }
}
.pop-holder {
  padding: 15px;
}
