.stepper-container {
    .single-stepper {
        cursor: pointer;
        border: none;
        border-bottom: 5px solid;
        padding: 7px;
        border-color: #d1d1d1;
        color: #d1d1d1;
        &.active {
            border-color: #679a2c;
            color: #679a2c;
        }
        .title {
            margin-left: 22px;
        }
    }
}
